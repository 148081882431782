@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.anchor-buttons {
  top: 0;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: functions.spacing(-5, 0);

  @include breakpoints.media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  &:global(.has-scrollbar) {
    justify-content: flex-start;
  }

  a {
    flex: 0 0 auto;
    flex-grow: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
