@mixin ButtonPrimaryContained {
  color: var(--button-contained-primary-color);
  background-color: var(--button-contained-primary-background);
  border-color: var(--button-contained-primary-border);
}

@mixin ButtonPrimaryContainedHover {
  background-color: var(--button-contained-primary-background-hover);
  border-color: var(--button-contained-primary-border-hover);
}

@mixin ButtonPrimaryOutlined {
  color: var(--button-outlined-primary-color);
  background-color: transparent;
  border-color: var(--button-outlined-primary-border);
}

@mixin ButtonPrimaryOutlinedHover {
  border-color: var(--button-outlined-primary-border-hover);
}

@mixin ButtonSecondaryContained {
  color: var(--button-contained-secondary-color);
  background-color: var(--button-contained-secondary-background);
  border-color: var(--button-contained-secondary-border);
}

@mixin ButtonSecondaryContainedHover {
  background-color: var(--button-contained-secondary-background-hover);
  border-color: var(--button-contained-secondary-border-hover);
}

@mixin ButtonSecondaryOutlined {
  color: var(--button-outlined-secondary-color);
  background-color: transparent;
  border-color: var(--button-outlined-secondary-border);
}

@mixin ButtonSecondaryOutlinedHover {
  background-color: transparent;
  border-color: var(--button-outlined-secondary-border-hover);
}

@mixin ButtonTertiaryContained {
  color: var(--button-contained-tertiary-color);
  background-color: var(--button-contained-tertiary-background);
  border-color: var(--button-contained-tertiary-border);
}

@mixin ButtonTertiaryContainedHover {
  background-color: var(--button-contained-tertiary-background-hover);
  border-color: var(--button-contained-tertiary-border-hover);
}

@mixin Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family);
  font-size: 0.875rem;
  line-height: 1.43;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  min-width: 120px;
  height: 40px;
  border: none;
  background: none;
  padding: 10px 24px;
  letter-spacing: normal;
  border: 1px solid var(--primary-dark);
  background: inherit;
  color: var(--secondary-dark);
  background-color: var(--primary-dark);
  text-wrap: nowrap;
  gap: 0.5rem;

  &.big {
    height: 48px;
  }

  &.primary {
    &.contained {
      @include ButtonPrimaryContained;

      &:hover {
        @include ButtonPrimaryContainedHover;
      }
    }

    &.outlined {
      @include ButtonPrimaryOutlined;

      &:hover {
        @include ButtonPrimaryOutlinedHover;
      }
    }
  }

  &.secondary {
    &.contained {
      @include ButtonSecondaryContained;

      &:hover {
        @include ButtonSecondaryContainedHover;
      }
    }

    &.outlined {
      @include ButtonSecondaryOutlined;

      &:hover {
        @include ButtonSecondaryOutlinedHover;
      }
    }
  }

  &.tertiary {
    &.contained {
      @include ButtonTertiaryContained;

      &:hover {
        @include ButtonTertiaryContainedHover;
      }
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
